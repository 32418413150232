import showModal from '@/modals/utils/showModal';
import { ConfirmModalType } from '@/storychief/components/ConfirmModal';

export default async function confirm({
  onModalClose = () => {},
  ...props
}: ConfirmModalType & { onModalClose?: () => void }): Promise<boolean> {
  let isConfirmed: boolean;

  try {
    await new Promise<void>((resolve, reject) => {
      showModal({
        id: 'ConfirmModal',
        props: {
          ...props,
          action(params) {
            if (props.action) {
              props.action(params);
            }
            isConfirmed = true;
            resolve();
          },
          onModalClose() {
            onModalClose();
            reject();
          },
        },
      });
    });
  } catch {
    isConfirmed = false;
  }

  return isConfirmed;
}
