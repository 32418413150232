import { modalsVar } from '@/graphql/cache';

export default function showModal({ id, props = null, data = null }) {
  const modals = modalsVar();
  const isExisting = modals.some((m) => m.id === id);

  if (!isExisting) {
    return modalsVar([
      ...modals,
      {
        id,
        props,
        data,
      },
    ]);
  }

  return modals;
}
